import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Body from '../components/Body';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <Hero heroImg='home' />
      <Layout>
        <Logo />
        <Body />
      </Layout>
      <Footer />
    </>
  );
}

export default Home;
