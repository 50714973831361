import React from 'react';
import styled from '@emotion/styled';
import nodeLogo from '../../images/node-logo.svg';
import reactLogo from '../../images/logo.svg';
import gatsbyLogo from '../../images/gatsby-logo.svg';
import graphqlLogo from '../../images/graphql-logo.svg';
import reduxLogo from '../../images/redux-logo.svg';
import { LABELS } from './Constants';
import { css } from '@emotion/core';
import styles from './Body.module.css';

const BodyContainer = styled('div')`
  text-align: center;
`;

const Logo = styled('img')`
  width: 80px;
`;

const LogoSmall = styled('img')`
  width: 50px;
`;

const LogoContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Body = () => {
  return (
    <BodyContainer>
      <p>
        {LABELS.HOME_WELCOME_MSG}
      </p>
      <LogoContainer>
        <Logo src={nodeLogo} alt="loading" />
        <img  className={styles.reactLogo} src={reactLogo} alt="loading" />
        <Logo src={gatsbyLogo} alt="loading" />
        <LogoSmall src={graphqlLogo} alt="loading" />
        <LogoSmall src={reduxLogo} alt="loading" />
        <p
          css={css`
            margin-top: 40px
          `}
        >
          {LABELS.HOME_TEXT}
        </p>
        <p
          css={css`
            margin-top: 40px
          `}
        >
          {LABELS.HOME_FOOTER}
        </p>
        <h2
          css={css`
            margin-top: 40px
          `}
        >
          <a
            href="mailto:reactitinfo@gmail.com"
            className={styles.contactAnchor}
            target="_blank"
            rel="noopener noreferrer"
          >
            {LABELS.SLOGAN}
          </a>
        </h2>
      </LogoContainer>
    </BodyContainer>
  )
};

export default Body;
