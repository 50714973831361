import React from 'react';
import logo from '../../images/atom.gif';
import styled from '@emotion/styled';

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const LogoImage = styled('img')`
  width: 60px;
  height: 60px;
  margin-right: 10px;
`;

const Logo = () => {
  return (
    <LogoContainer>
      <LogoImage src={logo} alt="loading..." />
      <h2>React IT</h2>
    </LogoContainer>
  )
};

export default Logo;
